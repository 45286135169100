import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, IconSearch } from '../../components';
import Geocoder, { GeocoderAttribution, CURRENT_LOCATION_ID } from './GeocoderMapbox';

import css from './TopbarSearchForm.module.css';

const currentLocationPrediction = { id: CURRENT_LOCATION_ID, predictionPlace: {} };

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);

    this.getGeocoder = this.getGeocoder.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInput = React.createRef();
  }

  getGeocoder() {
    // Create the Geocoder as late as possible only when it is needed.
    if (!this._geocoder) {
      this._geocoder = new Geocoder();
    }
    return this._geocoder;
  }

  async onSubmit(values) {
    // Set a default place in case Current Location fails
    let place = {
      search: '',
      predictions: [],
      selectedPlace: {},
    }

    try {
      // Set initial search on geoCoders's current location
      const location = await this.getGeocoder().getPlaceDetails(currentLocationPrediction);

      place = {
        search: location.address,
        selectedPlace: location,
      }

    } catch(err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    // Submit form with keywords, and possibly location 
    this.props.onSubmit({ keywords: values.keywords, location: place });
    // blur search input to hide software keyboard
    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, heroIconRoot, intl, isMobile, handleSubmit } = formRenderProps;

          const classes = classNames(rootClassName, className, isMobile ? css.mobileInput : null);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={heroIconRoot || css.desktopIcon}>
                <IconSearch rootClassName={css.iconRoot} />
              </div>
              <Field
                name="keywords"
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;
                  return (

                    <input
                      {...input}
                      className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                      id="keyword-search"
                      ref={this.searchInput}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'TopbarSearchForm.placeholder',
                      })}
                      autoComplete="off"
                    />
                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
