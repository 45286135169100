import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { ExternalLink } from '../../components';

import css from './SectionLocations.module.css';

import img from './images/img.png';
import mobile from './images/imgMobile.png';

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.subtitle}>
        <FormattedMessage id="SectionLocations.description" />
      </div>
      <div className={css.section}>
        <img src={img} alt={"products"} className={css.image} />
        <img src={mobile} alt={"products"} className={css.mobile} />
        <div className={css.buttonSection}>
          <ExternalLink 
            name="SearchPage"
            href="https://store.adventured.com"
            className={css.button}
          >
            <FormattedMessage id="SectionLocations.button" />
          </ExternalLink> 
        </div>
      </div>
      
    </div>
  );
};

SectionLocations.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
