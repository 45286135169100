import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import LogoImage from './logo.png';
import IconLogoImage from './logo_icon.png';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (<img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />);
  }

  return (<img className={className} src={IconLogoImage} alt={config.siteTitle} {...rest} width="40px" height="40px"/>);
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
