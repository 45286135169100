/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';

import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  ExternalLink,
} from '../../components';

import css from './TopbarMobileMenu.module.css';
// import { ConfirmSignupForm, LoginForm, SignupForm } from '../../forms';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    filters
  } = props;

  const user = ensureCurrentUser(currentUser);
  const categoriesFromConfig = findOptionsForSelectFilter('category', filters);
  const subCategoriesFromConfig = findOptionsForSelectFilter('activities', filters);

  const [showCategories, setShowCategories] = useState("close");
  const [showSubCategories, setShowSubCategories] = useState("close");

  const categoryItems = categoriesFromConfig.map((category) =>
    <button key={category.key} className={css.categories} name={category.label} onClick={() => {
      setShowCategories("close");
      setShowSubCategories(category.key);
    }}>
      <FormattedMessage id={"TopbarMobileMenu." + category.key} />
    </button>
  );
  const categories = (
    <div className={css.categoriesContainer}>{categoryItems}</div>
  );

  const subcategories = (
    <div className={css.subcategories}>
      {subCategoriesFromConfig.map((subcategory) => {
        if (subcategory.categories.includes("Instructors")) {
          return (
            <NamedLink value={subcategory.key} key={subcategory.key} className={css.categories} name="SearchPage"
              to={{
                search:
                  '?pub_activities=' + subcategory.label,
              }}>
              {subcategory.label}
            </NamedLink>
          );
        }
      })}
    </div>
  );
  const displayName = user.attributes.profile.firstName ? user.attributes.profile.firstName : "adventurer!";


  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={showCategories === "open" || showSubCategories !== "close" ? css.hide : css.content}>
          <span className={css.greeting}>
            <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
          </span>
          {login}
          {signup}
          <NamedLink className={css.postOfferLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.postOffer" />
          </NamedLink>
          <button type="button" className={css.categories} onClick={() => {
            setShowCategories("open");
          }}>
            <FormattedMessage id="TopbarMobileMenu.categories" />
          </button>
        </div>
        <div className={showCategories === "open" ? css.content : css.hide}>
          <button type="button" className={css.back} onClick={() => {
            setShowCategories("close");
          }}>
            Back
          </button>
          {subcategories}
        </div>
        <div className={showSubCategories !== "close" ? css.content : css.hide}>
          <button type="button" className={css.back} onClick={() => {
            setShowSubCategories("close");
            setShowCategories("open");
          }}>
            Back
          </button>
          {subcategories}
        </div>

      </div>
      // <NamedRedirect name="LandingPage" />

    );
  }

  // crear componente main--- devolver categories, login and signup
  // manejar variable ex.ruta, que dice cuales se debe visualizar con back, ---
  // no entiendo cómo desplegar o cambiar el arreglo
  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <span className={css.greeting}>
        <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
      </span>
      <div className={showCategories === "open" || showSubCategories !== "close" ? css.hide : css.content}>


        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <NamedLink className={css.navigationLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.postOffer" />
        </NamedLink>
        <button type="button" className={css.categoriesTitle} onClick={() => {
          setShowCategories("open");
        }}>
          <FormattedMessage id="TopbarMobileMenu.categories" />
        </button>
        <ExternalLink
          className={css.navigationLink}
          href="https://store.adventured.com"
        >
          <FormattedMessage id="TopbarMobileMenu.shop" />
        </ExternalLink>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
      </div>
      <div className={showCategories === "open" ? css.content : css.hide}>
        <button type="button" className={css.back} onClick={() => {
          setShowCategories("close");
        }}>
          Back
        </button>
        {subcategories}
      </div>
      <div className={showSubCategories !== "close" ? css.content : css.hide}>
        <button type="button" className={css.back} onClick={() => {
          setShowSubCategories("close");
          setShowCategories("open");
        }}>
          Back
        </button>
        {subcategories}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,

};

export default TopbarMobileMenu;
