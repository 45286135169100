import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { nonEmptyArray, required, composeValidators } from '../../util/validators';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, FieldCheckbox, FieldTextInput, Form, FieldRadioButton } from '../../components';

import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        invalid,
        intl,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        categories,
        campSitesAmenities,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const isCamping = categories === "Camp-Sites";
      const isRentals = categories === "Rentals";
      const isInstructors = categories === "Instructors";
      const isProduct = categories === "Buy-Sell";

      const showAsRequired = pristine;


      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const activitiesRequiredError = intl.formatMessage({
        id: 'EditListingFeaturesForm.activitiesRequired',
      });

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const validNumber = message => value => {
        return Number(value) % 1 === 0 && Number(value) > 0 ? undefined : message;
      };

      const options = findOptionsForSelectFilter('activities', filterConfig);

      const findcategoriesElements = (options) => {
        const realOptions = []
        options.map(activity => {
          if (activity.categories.includes(categories)) {
            realOptions.push(activity)
          }
        })
        return realOptions
      }

      const categoriesOptions = findcategoriesElements(options);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            className={css.features}
            id={name}
            name={name}
            options={categoriesOptions}
            label={isProduct || isRentals || isCamping ? <FormattedMessage id="EditListingFeaturesForm.category" /> : <FormattedMessage id="EditListingFeaturesForm.activities" />}
            description={isProduct || isRentals ? "Select the category of the item." : isCamping ? "Select the category for your camp site." : "Select the category of your listing."}
            validate={composeValidators(nonEmptyArray(activitiesRequiredError))}
            threeColumns
          />

          {isCamping ? (
            <FieldCheckboxGroup
            className={css.features}
            id={"campSitesAmenities"}
            name={"campSitesAmenities"}
            options={campSitesAmenities}
            label={<FormattedMessage id="EditListingFeaturesForm.amenities" />}
            description={"Add camp site amenities"}
            validate={composeValidators(nonEmptyArray(activitiesRequiredError))}
            threeColumns
          />
          ) : null}

          {!isRentals && !isProduct ? <>
            <FieldTextInput
              className={css.maxPartySizeInput}
              id="maxPartySize"
              name="maxPartySize"
              label={isCamping ? "How many people can attend your camp site?" : "How many people can attend a single session?"}
              placeholder="Max party size"
              validate={composeValidators(required("This field is required"), validNumber("This field has to be a whole number greater than 0"))}
              type="number"
              min="1"
            />
          </> : null}

          {isRentals ? <>
            <FieldTextInput
              className={css.maxPartySizeInput}
              id="maxProducts"
              name="maxProducts"
              label="How many of items do you have for rent?"
              placeholder="Max products for rent"
              validate={composeValidators(required("This field is required"), validNumber("This field has to be a whole number greater than 0"))}
              type="number"
              min="1"
            />

            <label className={css.onlineAvailabilityLabel}>Is overnight allowed?</label>
            <FieldCheckbox className={css.onlineAvailability} id="overnight" name="overnight" label="Overnight is available " />
          </> : null}

          {categories == "Guides" ?
            <FieldTextInput
              className={css.howLongActivity}
              id="howLongActivity"
              name="howLongActivity"
              label="How long is this guided activity?"
              placeholder="hours"
              validate={composeValidators(required("This field is required"), validNumber("This field has to be a whole number greater than 0"))}
              type="number"
              min="1"
            /> : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {"Next Step"}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
  campSitesAmenities: config.custom.campSitesAmenities,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  intl: intlShape.isRequired,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
