import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton } from '../../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const CancelActionButtonMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    showCancelRequestButton,
    cancelInProgress,
    cancelSaleError,
    requestCancelSaleInProgress,
    requestCancelSaleError,
    onCancelSale,
    onRequestCancelSale
  } = props;

  const buttonsDisabled = cancelInProgress || requestCancelSaleInProgress;

  const cancelErrorMessage = cancelSaleError || requestCancelSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.cancelSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {cancelErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          inProgress={cancelInProgress}
          disabled={buttonsDisabled}
          onClick={onCancelSale}
        >
          <FormattedMessage id="TransactionPanel.cancelButton" />
        </PrimaryButton>
      </div>
    </div>
  ) : showCancelRequestButton ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {cancelErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          inProgress={requestCancelSaleInProgress}
          disabled={buttonsDisabled}
          onClick={onRequestCancelSale}
        >
          <FormattedMessage id="TransactionPanel.requestCancelButton" />
        </PrimaryButton>
      </div>
    </div>
  ): null;
};

export default CancelActionButtonMaybe;

