import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconEnquiry.module.css';

const IconEnquiry = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="56.845" height="40" viewBox="0 0 56.845 40">
      <g>
        <path className={css.marketplaceColorFill} d="M4.616 85.282c-4.549 0-.074-4.616-.074-4.616l11.6-12.747c1.128-1.239 3.305-3.121 4.578-2.083s2.972 3.141 4.156 4.328l2.4 2.4a1.535 1.535 0 0 0 2.275 0L32 70.146a54.518 54.518 0 0 1 4.178-4.184c1.275-.955 3.443.861 4.568 2.1L52.28 80.806c4.551 4.476.074 4.476.074 4.476H4.616zm1.969-10.487c-1.128 1.24-2.776 3.4-3.89 4.65C1.438 80.858 0 81.931 0 79.455l.055-24.868c0-1.676-.042-4.873 1.1-4.3a7.725 7.725 0 0 1 1.8 1.45l9.386 7.68a25.94 25.94 0 0 1 3.723 3.225 1.251 1.251 0 0 1 0 1.728zm25.3-8.835c-1.3 1.063-2.7 2.572-3.424 2.574S26.307 67.061 25.005 66L4.582 49.437c-1.3-1.055-3.545-3.181-2.43-3.75a6 6 0 0 1 2.212-.4h47.994c4.321 0 2.124 2.134 2.124 2.134zm22.262 13.484c-1.116-1.251-2.765-3.409-3.892-4.65l-9.48-10.425a1.248 1.248 0 0 1 0-1.728 25.9 25.9 0 0 1 3.725-3.224l7.039-5.758a33.677 33.677 0 0 1 4.147-3.371c1.144-.574 1.095 2.624 1.1 4.3l.061 24.868c-.001 2.475-1.441 1.402-2.7-.012z" transform="translate(0 -45.282)"/>
      </g>
    </svg>
  );
};

IconEnquiry.defaultProps = {
  rootClassName: null,
  className: null,
};

IconEnquiry.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEnquiry;
