import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { likeProduct } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/LandingPage/SET_INITIAL_STATE';
export const QUERY_LISTINGS_REQUEST = 'app/LandingPage/QUERY_FEATURED_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_FEATURED_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/LandingPage/QUERY_FEATURED_LISTINGS_ERROR';

export const TOGGLE_LIKE_REQUEST = 'app/ListingPage/TOGGLE_LIKE_REQUEST';
export const TOGGLE_LIKE_SUCCESS = 'app/ListingPage/TOGGLE_LIKE_SUCCESS';
export const TOGGLE_LIKE_ERROR = 'app/ListingPage/TOGGLE_LIKE_ERROR';

export const SIGNUP_NEWSTELLER_REQUEST = 'app/LandingPage/QUERY_FEATURED_LISTINGS_REQUEST';
export const SIGNUP_NEWSTELLER_SUCCESS = 'app/LandingPage/QUERY_FEATURED_LISTINGS_SUCCESS';
export const SIGNUP_NEWSTELLER_ERROR = 'app/LandingPage/QUERY_FEATURED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  featuredPagination: null,
  featuredListingsIds: [],
  queryListingsError: null,
  queryListingsInProgress: false,
  likeInProgress: false,
  likeError: null,
  newstellerSignup: false,
  newstellerError: null
};

const resultIds = data => data.data.map(l => l.id);

export default function landingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        featuredListingsIds: state.featuredListingsIds,
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        featuredListingsIds: state.featuredListingsIds.concat(resultIds(payload.listings.data)),
        featuredPagination: payload.listings.data.meta,
        queryListingsInProgress: false
      };
    case QUERY_LISTINGS_ERROR:
      return { ...state, featuredListingsIds: [], queryListingsError: payload, queryListingsInProgress: false };

    case TOGGLE_LIKE_REQUEST:
      return { ...state, likeInProgress: payload.params, likeError: null };
    case TOGGLE_LIKE_SUCCESS:
      return { ...state, likeInProgress: false };
    case TOGGLE_LIKE_ERROR:
      return { ...state, likeInProgress: false, likeError: payload };

    case SIGNUP_NEWSTELLER_REQUEST:
      return { ...state, newstellerSignup: payload.params, newstellerError: null };
    case SIGNUP_NEWSTELLER_SUCCESS:
      return { ...state, newstellerSignup: false };
    case SIGNUP_NEWSTELLER_ERROR:
      return { ...state, newstellerSignup: false, newstellerError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryListingsRequest = () => ({
  type: QUERY_LISTINGS_REQUEST,
});

export const queryListingsSuccess = listings => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listings },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const toggleLikeRequest = requestAction(TOGGLE_LIKE_REQUEST);
export const toggleLikeSuccess = successAction(TOGGLE_LIKE_SUCCESS);
export const toggleLikeError = errorAction(TOGGLE_LIKE_ERROR);

// SDK method: sdk.currentUser.updateProfile
export const signupNewstellerRequest = params => ({
  type: SIGNUP_NEWSTELLER_REQUEST,
  payload: { params },
});
export const signupNewstellerSuccess = result => ({
  type: SIGNUP_NEWSTELLER_SUCCESS,
  payload: result.data,
});
export const signupNewstellerError = error => ({
  type: SIGNUP_NEWSTELLER_ERROR,
  payload: error,
  error: true,
});


// ================ Thunks ================ //

export const queryFeaturedListings = (searchParams) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const { page, currentActivity } = searchParams;

  const params = {
    pub_categories: 'Instructors',
    'sort': 'pub_views',
    include: ['author', 'images', 'createdAt', 'author.profileImage'],
    'fields.image': ['variants.default'],
    'fields.listing': ['title', 'price', 'publicData', 'metadata'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      // Pick only the id and type properties from the response listings
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(queryFeaturedListings({ page: 1 })),
  ]);
};

export const toggleLike = (user, itemId) => (dispatch, getState, sdk) => {
  dispatch(toggleLikeRequest(itemId));
  likeProduct({ user, itemId })
    .then(() => {
      const includes = {
        include: ['author', 'images'],
        'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
      };

      return sdk.listings.show({ id: itemId }, includes);
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(toggleLikeSuccess())
    })
    .catch(e => {
      dispatch(toggleLikeError(storableError(e)));
    })
};

export const signupNewsteller = (email) => (dispatch, getState, sdk) => {
  dispatch(signupNewstellerRequest());

  return sdk.currentUser.updateProfile({
    publicData: {
      newsteller: true,
      newstellerEmail: email,
    }
  }).then(response => {
    dispatch(signupNewstellerSuccess());

    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
    }
    const currentUser = entities[0];

    // Update current user in state.user.currentUser through user.duck.js
    dispatch(currentUserShowSuccess(currentUser));
  }).catch(e => dispatch(signupNewstellerError(storableError(e))));

}