import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, Field } from 'react-final-form';
import { Logo, InlineTextButton } from '..';
import css from './SectionNewsteller.module.css';

const SectionNewsteller = props => {

    const { 
      rootClassName,
      className,
      intl,
      onSignupNewsteller,
      newstellerSignup,
      currentUser
    } = props;
  
    const classes = classNames(rootClassName || css.root, className);
  
    // email
    const emailPlaceholder = intl.formatMessage({
      id: 'SectionNewsteller.emailPlaceholder',
    });

    const newstellerSignupSubmit = (formData) => {
      onSignupNewsteller(formData.email);
    }

    const isNewstellerUser = currentUser && currentUser.attributes.profile.publicData.newsteller;

    const MyForm = (
      <Form
        onSubmit={newstellerSignupSubmit}
        render={({ handleSubmit }) => (
          <form className={css.buttonSection} onSubmit={handleSubmit}>
            <div className={css.form}>
              <Field
                type="email"
                id="email"
                name="email"
                autoComplete="email"
                className={css.input}
                placeholder={emailPlaceholder}
                component="input"
              />
              <button
                className={css.button}
                type="submit"
              >
                {"Sign up!"}
              </button>
            </div>
            {isNewstellerUser ? <p className={css.newstellerTrue}>You have signed up to the newsteller already</p> : <p className={css.noSpam}>We promise we won’t send spam</p>}
          </form>
        )}
      />
    )
  
    return (
      <div className={classes}>
        <div className={css.section}>
          <div className={css.info}>
            <Logo format="desktop" className={css.logo} />
            <div className={css.title}>
              <FormattedMessage id="SectionNewsteller.title" /> {" "}
              <InlineTextButton className={css.link}>
                <FormattedMessage id="SectionNewsteller.newsteller" />
              </InlineTextButton>
            </div>
          </div>
          {MyForm}
        </div>
  
      </div>
    );
  
};

SectionNewsteller.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SectionNewsteller.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionNewsteller;
