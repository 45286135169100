import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, currentReviewsLimit, increaseLimit } = props;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  let sum = 0;
  for (let i = 0; i < reviews.length; i++) {
    sum += reviews[i].attributes.rating;
  }
  const avg = reviews.length ? sum / reviews.length : 0;

  const increaseBtn = reviews.length > currentReviewsLimit ? (<p className={css.readMore} onClick={increaseLimit}>Read more</p>) : null;

  return (
    <div className={css.sectionReviews}>
      <h2 className={css.reviewsHeading}>
        <FormattedMessage id="ListingPage.reviewsHeading" values={{ avg: avg, count: reviews.length }} />
      </h2>
      {fetchReviewsError ? reviewsError : null}
      <Reviews reviews={reviews} currentReviewsLimit={currentReviewsLimit} />
      {increaseBtn}
    </div>
  );
};

export default SectionReviews;
