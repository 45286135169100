import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import classNames from 'classnames';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    isProduct,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstThreeImages = hasImages ? listing.images.slice(0, 3) : [];

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id && !isProduct ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={!isProduct ? css.sectionImages : css.imagePortrait}>
      <div className={css.threeToTwoWrapper}>
        <div className={classNames(!isProduct ? css.aspectWrapper : css.aspectWrapperProduct, !hasImages ? css['aspectWrapper--empty'] : null)} onClick={handleViewPhotosClick}>
          {actionBar}
          {!isProduct ? firstThreeImages.map((image, i) => (
            <div className={css[`imagesWrapper--${hasImages && firstThreeImages.length}`]} key={i}>
              <ResponsiveImage
                rootClassName={classNames(css.rootForImage, css[`rootForImage--${i}`])}
                alt={title}
                image={image}
                variants={[
                  'landscape-crop',
                  'landscape-crop2x',
                  'landscape-crop4x',
                  'landscape-crop6x',
                ]}
              />
              {viewPhotosButton}

            </div>
          )) : (
            <div className={css[`imagesWrapper--0`]}>
              <ResponsiveImage
                rootClassName={css.rootForImagePortrait}
                alt={title}
                image={firstThreeImages[0]}
                variants={[
                  'square-small2x',
                ]}
              />
              {viewPhotosButton}
            </div>
          )}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div >
  );
};

export default SectionImages;
