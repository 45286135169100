import React, { useState } from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  txIsAccepted,
  txIsCanceled,
  txIsRequestCancel,
  txIsRequestDeclined,
  txIsDeclined,
  txIsEnquired,
  txIsRequested,
  txHasBeenDelivered,
  txIsPaymentExpired,
  txIsPaymentPending,
  // FTW-PRODUCT-START
  txIsPurchased,
  txIsDelivered,
  txIsDisputed,
  txIsReceived,
  txIsCompleted,
  txIsReviewedByCustomer,
  txIsReviewedByProvider,
  txIsReviewed,
  // FTW-PRODUCT-END
} from '../../util/transaction';
import { propTypes, DATE_TYPE_DATETIME } from '../../util/types';
import { createSlug, stringify } from '../../util/urlHelpers';
import { ensureCurrentUser, ensureListing, ensureTransaction } from '../../util/data';
import { formatDateIntoPartials } from '../../util/dates';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  BookingTimeInfo,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  UserDisplayName,
  UserNav,
  ButtonTabNavHorizontal,
} from '../../components';
import FeedSection from '../../components/TransactionPanel/FeedSection';
import FeedSectionProduct from '../TransactionPageProduct/TransactionPanel/FeedSectionProduct';
import { SendMessageForm } from '../../forms';

import { TopbarContainer, NotFoundPage } from '../../containers';
import config from '../../config';

import { loadData, fetchMessages, sendMessage } from './InboxPage.duck';
import css from './InboxPage.module.css';

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
    }),
    long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
  };
};

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';

  if (txIsEnquired(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsRequested(tx)) {
    const requested = isOrder
      ? {
        nameClassName: css.nameNotEmphasized,
        bookingClassName: css.bookingNoActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'InboxPage.stateRequested',
        }),
      }
      : {
        nameClassName: css.nameEmphasized,
        bookingClassName: css.bookingActionNeeded,
        lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({
          id: 'InboxPage.statePending',
        }),
      };

    return requested;
  } else if (txIsPaymentPending(tx)) {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
  } else if (txIsDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (txIsAccepted(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (txIsCanceled(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txIsRequestCancel(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateRequestCanceled',
      }),
    };
  } else if (txIsRequestDeclined(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateRequestDeclined',
      }),
    };
  } else if (txHasBeenDelivered(tx)) {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else if (txIsPurchased(tx)) {
    return {
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePurchased',
      }),
    };
  } else if (txIsDelivered(tx)) {
    return isOrder
      ? {
        stateClassName: css.stateActionNeeded,
        state: intl.formatMessage({ id: 'InboxPage.stateDeliveredCustomer' }),
      }
      : {
        stateClassName: css.stateNoActionNeeded,
        state: intl.formatMessage({ id: 'InboxPage.stateDeliveredProvider' }),
      };
  } else if (txIsDisputed(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDisputed',
      }),
    };
  } else if (txIsReceived(tx) || txIsCompleted(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateReceived',
      }),
    };
  } else if (txIsReviewedByCustomer(tx)) {
    const translationKey = isOrder ? 'InboxPage.stateReviewGiven' : 'InboxPage.stateReviewNeeded';
    return {
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewedByProvider(tx)) {
    const translationKey = isOrder ? 'InboxPage.stateReviewNeeded' : 'InboxPage.stateReviewGiven';
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewed(tx)) {
    return {
      stateClassName: css.stateConcluded,
      state: intl.formatMessage({
        id: 'InboxPage.stateReviewed',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

// Functional component as internal helper to print BookingTimeInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType } = props;
  const isEnquiry = txIsEnquired(tx);

  if (isEnquiry) {
    return null;
  }
  const listingAttributes = ensureListing(tx.listing).attributes;
  const timeZone = listingAttributes.availabilityPlan
    ? listingAttributes.availabilityPlan.timezone
    : 'Etc/UTC';

  // If you want to show the booking price after the booking time on InboxPage you can
  // add the price after the BookingTimeInfo component. You can get the price by uncommenting
  // sthe following lines:

  // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
  // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;

  // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
  // <div className={css.itemPrice}>{price}</div>

  return (
    <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
      <BookingTimeInfo
        bookingClassName={bookingClassName}
        isOrder={isOrder}
        intl={intl}
        tx={tx}
        unitType={unitType}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
      />
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

const createListingLink = (listing, otherUser, searchParams = {}, className = '') => {
  const listingId = listing.id && listing.id.uuid;
  const label = listing.attributes.title;
  const listingDeleted = listing.attributes.deleted;

  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        <Avatar user={otherUser} disableProfileLink />
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

export const InboxItem = props => {
  const { unitType, type, tx, intl, stateData } = props;
  const { customer, provider, listing, messages } = tx;
  const lastMessage = messages && messages[messages.length - 1] ? messages[messages.length - 1].attributes.content : null;
  const isOrder = type === 'order';
  const isProduct = listing?.attributes?.publicData?.categories === "Buy-Sell";
  const listingName = listing.attributes.title;

  if (isProduct) {
    const unitPurchase = tx.attributes?.lineItems?.find(
      item => item.code === unitType && !item.reversal
    );
    const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;

    const otherUser = isOrder ? provider : customer;
    const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
    const isOtherUserBanned = otherUser.attributes.banned;

    const isSaleNotification = !isOrder && txIsPurchased(tx);
    const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
    const lastTransitionedAt = formatDateIntoPartials(tx.attributes.lastTransitionedAt, intl);

    const linkClasses = classNames(css.itemLink, {
      [css.bannedUserLink]: isOtherUserBanned,
    });

    return (
      <div className={css.item}>
        <div className={css.itemAvatar}>
          <Avatar user={otherUser} />
        </div>
        <NamedLink
          className={linkClasses}
          name={isOrder ? 'OrderDetailsPageProduct' : 'SaleDetailsPageProduct'}
          params={{ id: tx.id.uuid }}
        >
          <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
          <div className={css.transactionInfo}>
            <div className={css.itemInfo}>
              <div className={css.itemListing}>{listingName + " ~ "}</div>
              <div className={css.itemUsername}>{otherUserDisplayName}</div>
              <div className={css.itemOrderInfo}>
                <FormattedMessage id="InboxPage.quantity" values={{ quantity }} />
              </div>
            </div>
            <div className={css.messages}>
              {lastMessage}
            </div>
          </div>
          <div className={css.itemState}>
            <div className={classNames(css.stateName, stateData.stateClassName)}>
              {stateData.state}
            </div>
            <div
              className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
              title={lastTransitionedAt.dateAndTime}
            >
              {lastTransitionedAt.date}
            </div>
          </div>
        </NamedLink>
      </div>
    );
  } else {
    const otherUser = isOrder ? provider : customer;
    const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
    const isOtherUserBanned = otherUser.attributes.banned;

    const isSaleNotification = !isOrder && txIsRequested(tx);
    const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
    const lastTransitionedAt = formatDate(intl, tx.attributes.lastTransitionedAt);

    const linkClasses = classNames(css.itemLink, {
      [css.bannedUserLink]: isOtherUserBanned,
    });

    const listingLink = listing ? createListingLink(listing, otherUser) : null;

    return (
      <div className={css.item}>
        <div className={css.itemAvatar}>
          {isOrder && listing ? listingLink : <Avatar user={otherUser} />}
        </div>
        <NamedLink
          className={linkClasses}
          name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
        >
          <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
          <div className={css.transactionInfo}>
            <div className={css.itemInfo}>
              <div className={css.itemListing}>{listingName + " ~ "}</div>
              <div className={classNames(css.itemUsername, stateData.nameClassName)}>
                {otherUserDisplayName}
              </div>
              <BookingInfoMaybe
                bookingClassName={stateData.bookingClassName}
                intl={intl}
                isOrder={isOrder}
                tx={tx}
                unitType={unitType}
              />
            </div>
            <div className={css.messages}>
              {lastMessage}
            </div>
          </div>
          <div className={css.itemState}>
            <div className={classNames(css.stateName, stateData.stateClassName)}>
              {stateData.state}
            </div>
            <div
              className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
              title={lastTransitionedAt.long}
            >
              {lastTransitionedAt.short}
            </div>
          </div>
        </NamedLink>
      </div>
    );
  }
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxItemMessage = props => {
  const { unitType, type, tx, intl, stateData, onShowMessages } = props;
  const { customer, provider, listing, messages } = tx;
  const lastMessage = messages && messages[messages.length - 1] ? messages[messages.length - 1].attributes.content : null;
  const isOrder = type === 'order';
  const isProduct = listing?.attributes?.publicData?.categories === "Buy-Sell";
  const listingName = listing.attributes.title;

  const lastMessageDate = tx.messages[tx.messages.length]?.attributes?.createdAt

  if (isProduct) {
    const unitPurchase = tx.attributes?.lineItems?.find(
      item => item.code === unitType && !item.reversal
    );
    const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;

    const otherUser = isOrder ? provider : customer;
    const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
    const isOtherUserBanned = otherUser.attributes.banned;

    const isSaleNotification = !isOrder && txIsPurchased(tx);
    const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
    const lastTransitionedAt = lastMessageDate ? formatDateIntoPartials(lastMessageDate, intl) : formatDateIntoPartials(tx.attributes.lastTransitionedAt, intl);

    const linkClasses = classNames(css.itemLink, {
      [css.bannedUserLink]: isOtherUserBanned,
    });

    return (
      <div className={css.item}>
        <div className={css.itemAvatar}>
          <Avatar user={otherUser} />
        </div>
        <div
          className={linkClasses}
          name={isOrder ? 'OrderDetailsPageProduct' : 'SaleDetailsPageProduct'}
          params={{ id: tx.id.uuid }}
        >
          <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
          <div className={css.transactionInfo}>
            <div className={css.itemInfo}>
              <div className={css.itemListing}>{listingName + " ~ "}</div>
              <div className={css.itemUsername}>{otherUserDisplayName}</div>
            </div>
            <div className={css.messages}>
              {lastMessage}
            </div>
          </div>
          <div className={css.itemState}>
            <div
              className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
              title={lastTransitionedAt.dateAndTime}
            >
              {lastTransitionedAt.date}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    const otherUser = isOrder ? provider : customer;
    const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
    const isOtherUserBanned = otherUser.attributes.banned;

    const isSaleNotification = !isOrder && txIsRequested(tx);
    const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
    const lastTransitionedAt = lastMessageDate ? formatDate(intl, lastMessageDate) : formatDate(intl, tx.attributes.lastTransitionedAt);

    const linkClasses = classNames(css.itemLink, {
      [css.bannedUserLink]: isOtherUserBanned,
    });

    const listingLink = listing ? createListingLink(listing, otherUser) : null;

    return (
      <div className={css.item}>
        <div className={css.itemAvatar}>
          {isOrder && listing ? listingLink : <Avatar user={otherUser} />}
        </div>
        <div
          className={linkClasses}
          name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
        >
          <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
          <div className={css.transactionInfo}>
            <div className={css.itemInfo}>
              <div className={css.itemListing}>{listingName + " ~ "}</div>
              <div className={classNames(css.itemUsername, stateData.nameClassName)}>
                {otherUserDisplayName}
              </div>
            </div>
            <div className={css.messages}>
              {lastMessage}
            </div>
          </div>
          <div className={css.itemState}>
            <div
              className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
              title={lastTransitionedAt.long}
            >
              {lastTransitionedAt.short}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

InboxItemMessage.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    currentUserListing,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    onShowMessages,
    sendMessageInProgress,
    sendMessageError,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    onShowMoreMessages
  } = props;
  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const validTab = tab === 'orders' || tab === 'sales';
  if (!validTab) {
    return <NotFoundPage />;
  }

  const isOrders = tab === 'orders';

  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = isOrders ? ordersTitle : salesTitle;

  const [showMessages, setShowMessages] = useState(false);

  const toTxItem = tx => {
    const type = isOrders ? 'order' : 'sale';
    const stateData = txState(intl, tx, type);
    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem unitType={unitType} type={type} tx={tx} intl={intl} stateData={stateData} />
      </li>
    ) : null;
  };

  const [loadMessages, setLoadMessages] = useState(null);

  const toTxItemMessages = tx => {
    const type = isOrders ? 'order' : 'sale';
    const stateData = txState(intl, tx, type);
    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem} onClick={function () {
        setShowMessages(true);
        onShowMessages(tx.id.uuid).then(res => {
          setLoadMessages({
            tx: tx,
            messages: res
          });
        }).then(() => {
          const div = document.getElementById("conversation");
          div.scrollTop = div.scrollHeight;
        })
      }}>
        <InboxItemMessage unitType={unitType} type={type} tx={tx} intl={intl} stateData={stateData} />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id={isOrders ? 'InboxPage.noOrdersFound' : 'InboxPage.noSalesFound'} />
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
      : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  };
  const hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;

  const tabs = [
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.ordersTabTitle" />
        </span>
      ),
      selected: isOrders,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.salesTabTitle" />
          {providerNotificationBadge}
        </span>
      ),
      selected: !isOrders,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'sales' },
      },
    },
  ];

  const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

  const [inboxItem, setInboxItem] = useState("Requests");
  const [sendMessageFormFocused, setSendMessageFormFocused] = useState(false);

  //Send Message

  const onSendMessageFormFocus = () => {
    setSendMessageFormFocused(true)
  }

  const onSendMessageFormBlur = () => {
    setSendMessageFormFocused(false)
  }

  const onMessageSubmit = (values, form) => {
    const message = values.message ? values.message.trim() : null;
    const { onSendMessage } = props;
    const ensuredTransaction = ensureTransaction(loadMessages.tx);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
      }).then(() => {
        onShowMessages(loadMessages.tx.id.uuid).then(res => {
          setLoadMessages({
            tx: loadMessages.tx,
            messages: res
          })
          const div = document.getElementById("conversation");
          div.scrollTop = div.scrollHeight;
        })
        
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });

  }

  //Sendmessage

  const inboxTabs = [
    {
      text: (
        <span className={css.tab}>
          General
        </span>
      ),
      selected: inboxItem === "Messages",
      onClick: function () {
        setInboxItem("Messages")
      },
    },
    {
      text: (
        <span className={css.tab}>
          Requests
        </span>
      ),
      selected: inboxItem === "Requests",
      onClick: function () {
        setInboxItem("Requests")
      },
    },
  ]

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation className={css.sideNavCont}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
          <UserNav selectedPageName="InboxBasePage" listing={currentUserListing} />
          <ButtonTabNavHorizontal className={css.tabsContainer} tabRootClassName={css.tabs} tabs={inboxTabs} tabButton={css.tabButton} tabButtonSelected={css.tabButtonSelected} />
        </LayoutWrapperTopbar>
        {inboxItem === "Requests" ? (
          <LayoutWrapperSideNav className={css.navigation}>
            <h1 className={css.title}>
              <FormattedMessage id="InboxPage.title" />
            </h1>
            {currentUserListing ? nav : <div className={css.navPlaceholder} />}
          </LayoutWrapperSideNav>
        ) :
          (<LayoutWrapperSideNav className={showMessages ? classNames(css.navigation, css.hide) : css.navigation}>
            <LayoutWrapperMain>
              {error}
              <ul className={css.itemList}>
                {!fetchInProgress ? (
                  transactions.map(toTxItemMessages)
                ) : (
                  <li className={css.listItemsLoading}>
                    <IconSpinner />
                  </li>
                )}
                {noResults}
              </ul>
              {pagingLinks}
            </LayoutWrapperMain>
          </LayoutWrapperSideNav>)
        }
        {inboxItem === "Requests" ? (
          <LayoutWrapperMain className={css.conversationContainer}>
            {error}
            <ul className={css.itemList}>
              {!fetchInProgress ? (
                transactions.map(toTxItem)
              ) : (
                <li className={css.listItemsLoading}>
                  <IconSpinner />
                </li>
              )}
              {noResults}
            </ul>
            {pagingLinks}
          </LayoutWrapperMain>
        ) :
          <LayoutWrapperMain className={css.conversationContainer}>
            <div className={showMessages ? css.itemList : css.hide}>
              <button type="button" className={css.backButton} onClick={() => setShowMessages(false)}>Back</button>
              {loadMessages && loadMessages.tx.listing?.attributes?.publicData?.categories === 'Buy-Sell'? (
                <div className={css.conversation}>
                  <div id="conversation" className={css.feedContainer}>
                    <FeedSectionProduct
                      currentTransaction={loadMessages.tx}
                      currentUser={currentUser}
                      messages={loadMessages.messages}
                      fetchMessagesError={fetchMessagesError}
                      fetchMessagesInProgress={fetchMessagesInProgress}
                      oldestMessagePageFetched={oldestMessagePageFetched}
                      totalMessagePages={totalMessagePages}
                      onShowMoreMessages={() => onShowMoreMessages(loadMessages.tx.id)}
                    />
                  </div>
                  <SendMessageForm
                    formId={'TransactionPanel.SendMessageForm'}
                    rootClassName={css.sendMessageForm}
                    messagePlaceholder={"Send a message"}
                    inProgress={sendMessageInProgress}
                    sendMessageError={sendMessageError}
                    onFocus={onSendMessageFormFocus}
                    onBlur={onSendMessageFormBlur}
                    onSubmit={onMessageSubmit}
                  />
                </div>
              ) : loadMessages ? (
                <div className={css.conversation}>
                  <div id="conversation" className={css.feedContainer}>
                  <FeedSection
                    currentTransaction={loadMessages.tx}
                    currentUser={currentUser}
                    messages={loadMessages.messages}
                    fetchMessagesError={fetchMessagesError}
                    fetchMessagesInProgress={fetchMessagesInProgress}
                    oldestMessagePageFetched={oldestMessagePageFetched}
                    totalMessagePages={totalMessagePages}
                    onShowMoreMessages={() => onShowMoreMessages(loadMessages.tx.id)}
                  />
                  </div>
                  <SendMessageForm
                    formId={'TransactionPanel.SendMessageForm'}
                    rootClassName={css.sendMessageForm}
                    messagePlaceholder={"Send a message"}
                    inProgress={sendMessageInProgress}
                    sendMessageError={sendMessageError}
                    onFocus={onSendMessageFormFocus}
                    onBlur={onSendMessageFormBlur}
                    onSubmit={onMessageSubmit}
                  />
                </div>
              ) : null}
            </div>
          </LayoutWrapperMain>
        }
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserListing: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs, messages, sendMessageInProgress,
    sendMessageError, fetchMessagesInProgress, fetchMessagesError, totalMessagePages, oldestMessagePageFetched, } = state.InboxPage;
  const {
    currentUser,
    currentUserListing,
    currentUserNotificationCount: providerNotificationCount,
  } = state.user;
  return {
    currentUser,
    currentUserListing,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    sendMessageInProgress,
    sendMessageError,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowMessages: txId => dispatch(fetchMessages(txId)),
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
  }
};

const InboxPage = compose(
  connect(mapStateToProps,
    mapDispatchToProps),
  injectIntl
)(InboxPageComponent);

InboxPage.loadData = loadData;

export default InboxPage;
