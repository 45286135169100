import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Button, IconSearch, Form, LocationAutocompleteInput, FieldRadioButton } from '../../components';
import Geocoder from './GeocoderMapbox';

import css from './TopbarSearchLocationForm.module.css';
import config from '../../config';

class TopbarLocationFormComponent extends Component {
  constructor(props) {
    super(props);

    this.getGeocoder = this.getGeocoder.bind(this);
    this.searchInput = React.createRef();
  }

  getGeocoder() {
    // Create the Geocoder as late as possible only when it is needed.
    if (!this._geocoder) {
      this._geocoder = new Geocoder();
    }
    return this._geocoder;
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.props.onSubmit}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, desktopFieldsWrapper, heroIconRoot, intl, isMobile, handleSubmit, isMain, values } = formRenderProps;

          const classes = classNames(rootClassName, className, isMobile ? css.mobileInput : null);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;
          const desktopSearchInputRootClass = css.desktopSearchInputRoot;
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {!isMain && (
                <div className={css.typeWrapper}>
                  <FieldRadioButton
                    className={css.locationType}
                    name="pub_types"
                    id="pub_typesin-person"
                    value="in-person"
                    label={intl.formatMessage({ id: 'TopbarSearchForm.inPerson' })}
                  />

                  <FieldRadioButton
                    className={css.locationType}
                    name="pub_types"
                    id="pub_typesOnline"
                    value="online"
                    label={intl.formatMessage({ id: 'TopbarSearchForm.online' })}
                  />
                </div>
              )}
              <div className={desktopFieldsWrapper || css.fieldsWrapper}>
                <div className={heroIconRoot || css.desktopIcon}>
                </div>
                <Field
                  name="keywords"
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;
                    return (
                      <input
                        {...input}
                        className={isMobile ? css.mobileSearchInputRoot : desktopSearchInputRootClass}
                        id="keyword-search-home"
                        ref={this.searchInput}
                        type="text"
                        placeholder={intl.formatMessage({
                          id: 'TopbarSearchForm.placeholderHero',
                        })}
                        autoComplete="off"
                      />
                    );
                  }}
                />
                {isMain || values.pub_types !== 'in-person' ? (
                  <Button type="submit" className={css.submitButton}>
                    <IconSearch rootClassName={css.icon} />
                  </Button>
                ) : null}
              </div>
              {!isMain && values.pub_types === 'in-person' && (
                <div className={desktopFieldsWrapper || css.fieldsWrapper}>
                      <Field
                        name="location"
                        render={({ input, meta }) => {
                          const { onChange, ...restInput } = input;
                          const handleChange = location => {
                            if (location.selectedPlace) {
                              // Note that we use `onSubmit` instead of the conventional
                              // `handleSubmit` prop for submitting. We want to autosubmit
                              // when a place is selected, and don't require any extra
                              // validations for the form.
                              // props.onSubmit({ location });
                            }
                          };
                          const searchOnChange = value => {
                            onChange(value);
                            handleChange(value);
                          };

                          const searchInput = { ...restInput, onChange: searchOnChange };
                          return (
                            <div className={isMobile ? css.mobileInputRoot : desktopInputRootClass}>
                              <LocationAutocompleteInput
                                placeholder={intl.formatMessage({ id: 'TopbarSearchLocationForm.placeholder' })}
                                iconClassName={css.searchInputIcon}
                                inputClassName={css.searchInput}
                                predictionsClassName={css.searchPredictions}
                                input={searchInput}
                                meta={meta}
                              />
                            </div>

                          );
                        }}
                      />
                  <Button type="submit" className={css.submitButton}>
                    <IconSearch rootClassName={css.icon} />
                  </Button>
              </div>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarLocationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  filterConfig: config.custom.filters
};

TopbarLocationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarLocationForm = injectIntl(TopbarLocationFormComponent);

export default TopbarLocationForm;
