import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const CancelRequestActionButtonMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptRequestCancelSaleInProgress,
    acceptRequestCancelSaleError,
    declineRequestCancelSaleInProgress,
    declineRequestCancelSaleError,
    onAcceptRequestCancelSale,
    onDeclineRequestCancelSale,
  } = props;

  const buttonsDisabled = acceptRequestCancelSaleInProgress  || declineRequestCancelSaleInProgress;

  const cancelErrorMessage = acceptRequestCancelSaleError || declineRequestCancelSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.cancelSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <div className={classes}>
      <div className={css.actionErrors}>
        {cancelErrorMessage}
      </div>
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          inProgress={declineRequestCancelSaleInProgress}
          disabled={buttonsDisabled}
          onClick={onDeclineRequestCancelSale}
        >
          <FormattedMessage id="TransactionPanel.declineCancelButton" />
        </PrimaryButton>
        <SecondaryButton
          inProgress={acceptRequestCancelSaleInProgress}
          disabled={buttonsDisabled}
          onClick={onAcceptRequestCancelSale}
        >
          <FormattedMessage id="TransactionPanel.acceptCancelButton" />
        </SecondaryButton>
      </div>
    </div>
  ) : null;
};

export default CancelRequestActionButtonMaybe;
