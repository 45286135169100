import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { IconEye, NamedLink, ResponsiveImage, Avatar } from '../../components';
// import Button from '../Button/Button';
import MessageIcon from '@material-ui/icons/Message';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { IconButton, CircularProgress } from '@material-ui/core';
import "@fontsource/roboto";

import css from './ListingFeaturedCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingFeaturedCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    currentUser,
    setActiveListing,
    onToggleLike,
    likeInProgress,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, metadata } = currentListing.attributes;
  const { types } = currentListing.attributes.publicData;
  const { videoChatPrice = { amount: '0', currency: 'USD'} , phonePrice = { amount: '0', currency: 'USD'} } = currentListing.attributes.publicData;
  const displayPrice = types?.includes('video-chat') ? new Money(videoChatPrice?.amount, videoChatPrice?.currency) : types?.includes('video-chat') ? new Money(phonePrice?.amount, phonePrice?.currency) : price;

  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(displayPrice, intl);

  const activities = publicData.activities ? publicData.activities : [];
  const category = publicData.categories ? publicData.categories : "";
  const listingAuthor = currentListing.author;
  const authorName = listingAuthor.attributes.profile.displayName;

  const views = publicData.views > 10000 ? parseInt(publicData.views/1000) + "K" : publicData.views ? publicData.views : 0;
  const comments = Array.isArray(metadata?.comments) ? metadata.comments.length : 0;

  const activitiesLabel = activities.map((act, i) => {
    return act.charAt(0).toUpperCase() + act.slice(1) + (i === activities.length - 1 ? '' : ', ');
  });

  const isProduct = listing.attributes.publicData.categories === "Buy-Sell";

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : isProduct 
      ? 'ListingCard.perProduct'
      : 'ListingCard.perUnit';

  // addRemoveLike
  const likedByCurrent = (!!currentUser && !!metadata && Array.isArray(metadata.likedBy) && metadata.likedBy.includes(currentUser.id.uuid));
  const currentLikeInProgress = likeInProgress === id;

  return (
    <div
      className={css.threeToTwoWrapper}
      onMouseEnter={() => setActiveListing(currentListing.id)}
      onMouseLeave={() => setActiveListing(null)}
    >
      <div className={css.aspectWrapper}>
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={['default', 'landscape-crop2x']}
        />
        <div className={css.overlay}>
        </div>
        {onToggleLike ? <IconButton onClick={e => { e.stopPropagation(); onToggleLike(id) }} className={likedByCurrent ? css.likedButton : css.likeButton} aria-label="like">
          {currentLikeInProgress ? <CircularProgress color="inherit" size="24px" /> : likedByCurrent ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconButton> : null}
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <div className={css.infoCard}>
            <div className={css.rowOne}>
              <div className={css.flex}>
                <Avatar className={css.avatar} user={listingAuthor} disableProfileLink />
                <div className={css.grid}>
                  <div className={css.userName}>
                    {richText(authorName, { longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS, longWordClass: css.longWord, })}
                  </div>
                </div>
              </div>
            </div>
            <div className={css.rowTwo}>
              <p className={css.adventureTitle}>{richText(title, { longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS, longWordClass: css.longWord, })}</p>
              <p className={css.adventureDescription}>{activitiesLabel}</p>
            </div>
            <div>
              {/*Price Section*/}
              <div className={css.rowThree}>
                <div className={css.price}>
                  <div className={css.priceValue} title={priceTitle}>
                    Price: {formattedPrice}
                  </div>
                  <div className={css.perUnit}>
                    <FormattedMessage id={unitTranslationKey} />
                  </div>
                </div>
              </div>
              {/*Price Section*/}
              {/*Views Section*/}
              <div className={css.rowFour}>
                <div className={css.iconsDiv}>
                  <span className={css.iconsHolder}>
                    <IconEye className={css.icons} /> {views}
                  </span>
                  <span className={css.iconsHolder}>
                    <MessageIcon className={css.icons} /> {comments}
                  </span>
                </div>
                <button className={css.button}>
                  {category === "Buy-Sell" ? "Buy Now" : "Book now"}
                </button>
              </div>
              {/*Views Reviews*/}
            </div>
          </div>
        </NamedLink>
      </div>
    </div>
  );
};

ListingFeaturedCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingFeaturedCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingFeaturedCardComponent);
