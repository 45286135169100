import classNames from 'classnames';
import React, { useCallback, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
// import { nonEmptyArray, composeValidators } from '../../../util/validators';
import { AddImages, ValidationError } from '../../components';
import { isUploadImageOverLimitError } from '../../util/errors';
import { FormattedMessage } from '../../util/reactIntl';
import { Field } from 'react-final-form';
import { nonEmptyArray, composeValidators } from '../../util/validators';

import css from './EditListingPhotosForm.module.css';

const ACCEPT_IMAGES = 'image/*';

function DropzoneImageUploader(props) {
  const {
    className,
    disabled,
    fetchErrors,
    form,
    intl,
    images,
    onRemoveImage,
    onUpdateImageOrder,
    chooseImageText,
  } = props;

  const [imageUploadRequested, setImageUploadRequested] = useState(false);

  const imageRequiredMessage = intl.formatMessage({
    id: 'EditListingPhotosForm.imageRequired',
  });

  const onImageUploadHandler = useCallback(file => {
    if (file) {
      setImageUploadRequested(true);
      props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          setImageUploadRequested(false);
        })
        .catch(() => {
          setImageUploadRequested(false);
        });
    }
  }, [props]);

  const onDrop = useCallback(acceptedFiles => {
    form.change(`images`, acceptedFiles);
    form.blur(`images`);

    acceptedFiles.forEach(file => {
      onImageUploadHandler(file);
    });
  }, [form, onImageUploadHandler]);

  const { getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: ACCEPT_IMAGES,
    onDrop,
    disabled
  });

  const classes = classNames(css.dropzone, className);

  const { uploadImageError } =
    fetchErrors || {};
  const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

  let uploadImageFailed = null;

  if (uploadOverLimit) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
      </p>
    );
  } else if (uploadImageError) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
      </p>
    );
  }

  const style = classNames(
    css.dropzoneBase,
    isDragActive ? css.dropzoneActive : '',
    isDragAccept ? css.dropzoneAccept : '',
    isDragReject ? css.dropzoneReject : ''
  );

  return (
    <div className={classes}>
      <div {...getRootProps({ className: style })}>
        <AddImages
          className={css.imagesField}
          images={images}
          thumbnailClassName={css.thumbnail}
          savedImageAltText={intl.formatMessage({
            id: 'EditListingPhotosForm.savedImageAltText',
          })}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
        >
          <Field
            id="addImage"
            name="addImage"
            accept={ACCEPT_IMAGES}
            form={null}
            label={chooseImageText}
            type="file"
            disabled={imageUploadRequested}
            multiple
          >
            {fieldprops => {
              const { accept, input, label, disabled: fieldDisabled } = fieldprops;
              const { name, type } = input;
              const onChange = e => {
                const files = e.target.files;
                for (let file of files) {
                  form.change(`addImage`, file);
                  form.blur(`addImage`);
                  onImageUploadHandler(file);
                }
              };
              const inputProps = { accept, id: name, name, onChange, type };
              return (
                <div className={css.addImageWrapper}>
                  <div className={css.aspectRatioWrapper}>
                    {fieldDisabled ? null : (
                      <input {...inputProps} className={css.addImageInput} multiple/>
                    )}
                    <label htmlFor={name} className={css.addImage}>
                      {label}
                    </label>
                  </div>
                </div>
              );
            }}
          </Field>

          <Field
            component={props => {
              const { input, meta } = props;
              return (
                <div className={css.imageRequiredWrapper}>
                  <input {...input} multiple/>
                  <ValidationError fieldMeta={meta} />
                </div>
              );
            }}
            name="images"
            type="hidden"
            validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
          />
        </AddImages>
      </div>
      {uploadImageFailed}
    </div>
  );
}

export default DropzoneImageUploader;