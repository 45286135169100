import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingFeaturedCard, Button, ButtonTabNavHorizontal } from '../../components';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';


import css from './SectionFeatured.module.css';

const SectionFeatured = props => {
  const {
    rootClassName,
    className,
    featuredListings,
    inProgress,
    currentActivity,
    setCurrentActivity,
    onToggleLike,
    likeInProgress,
    currentUser
  } = props;


  const classes = classNames(rootClassName || css.root, className);

  const handleSubmit = () => {
    const { currentSearchParams } = props;

    const { history } = props;

    const searchParams = {
      ...currentSearchParams,
      pub_categories: currentActivity !== "Popular" ? currentActivity : undefined,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  if (!featuredListings.length) {
    return null;
  }
  
  const tabs = [
    {
      text: (
        <p className={css.tab}>
          <FormattedMessage id="SectionFeatured.popular" />
        </p>
      ),
      selected: currentActivity === "Popular",
      onClick: function () {
        setCurrentActivity("Popular")
      },
    },
  ]

  return (
    <div className={classes}>
      <ButtonTabNavHorizontal className={css.tabsContainer} tabRootClassName={css.tabs} tabs={tabs} tabButton={css.tabButton} tabButtonSelected={css.tabButtonSelected}/>
      <div className={css.featured}>
          {featuredListings.map((l, i) => {
          if(l.attributes.publicData.categories === currentActivity || currentActivity === "Popular" && i < 12){
            return (
                <ListingFeaturedCard
                  key={i}
                  className={css.featuredItem}
                  listing={l}
                  onToggleLike={onToggleLike}
                  likeInProgress={likeInProgress}
                  currentUser={currentUser}
                />
            )}
          }
          )}
        </div>

      <div className={css.buttonWrapper}>
        <Button onClick={handleSubmit} inProgress={inProgress} className={css.seeMoreButton}>
          See More
        </Button>
      </div>
    </div>
  );
};

SectionFeatured.defaultProps = { rootClassName: null, className: null, featuredListings: [] };

const { string } = PropTypes;

SectionFeatured.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFeatured;
