import React from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../';

import css from './VideoChat.module.css';
import { FormattedDate } from 'react-intl';

const VideoChat = (props) => {

    const {
        className, 
        roomName,
        transactionStartDate,
        isCustomer
    } = props;

    const role = isCustomer ? 'adventurer' : 'instructor';
    const videoLink = process.env.REACT_APP_VIDEO_CHAT_URL + roomName + "/" + role;

    const rootClasses = classNames(css.root, className);

    return (
        <div className={rootClasses}>
                <div className={css.videoForm}>
                    <h2>
                        Join the room on {" "}
                        <FormattedDate 
                            weekday= 'long'
                            hour= 'numeric'
                            minute= 'numeric'
                            month= 'long'
                            day= 'numeric'
                            value={transactionStartDate}
                        />
                    </h2>
                    <ExternalLink 
                        className={css.videoButton}
                        href={videoLink}
                    >
                        Join the room
                    </ExternalLink>
                </div>
        </div>
    )
}

export default VideoChat;