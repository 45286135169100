import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { IconSpinner, Form, FieldCheckbox, FieldSelect, PrimaryButton } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';

import css from './BookingTimeForm.module.css';
import { required } from '../../util/validators';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues, isCamping, currentListing) {
    const { bookingStartTime, bookingEndTime, types, partySize } = formValues.values;
    const isOvernight = this.props.isOvernight;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;
    //If the booking is caming or overnight, we need to add one day to the end date
    if(endDate && (isCamping || this.props.isOvernight)){
      endDate.getDate() === startDate.getDate() ? endDate.setDate(endDate.getDate()+1) : endDate;
      endDate.setHours(currentListing.attributes.publicData.endSessionTime)
      //If the booking is for Guides, we need to add the duration of the session to the start date to get the end date
    } else if(endDate && currentListing.attributes.publicData.categories === "Guides"){
      endDate.setHours(startDate.getHours() + parseInt(currentListing.attributes.publicData.howLongActivity, 10))
    }
    //If the booking is camping set the start date to the selected start session hour
    if(startDate && (isCamping || this.props.isOvernight)){
      startDate.setHours(currentListing.attributes.publicData.startSessionTime)
    }

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    if (bookingStartTime && bookingEndTime  && !this.props.fetchLineItemsInProgress){
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, types, partySize, isOvernight },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, isOvernight, setIsOvernight, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        isOvernight={isOvernight}
            setIsOvernight={setIsOvernight}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            hasGroupClasses,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            currentListing,
            filterConfig,
            hasPhoneNumber
          } = fieldRenderProps;

          const isCamping = currentListing.attributes.publicData.categories === "Camp-Sites";
          const isGuides = currentListing.attributes.publicData.categories === "Guides";

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;
          //If the booking is caming or overnight, we need to add one day to the end date
          if(endDate && (isCamping || this.props.isOvernight)){
            endDate.getDate() === startDate.getDate() ? endDate.setDate(endDate.getDate()+1) : endDate;
            endDate.setHours(currentListing.attributes.publicData.endSessionTime)
            //If the booking is for Guides, we need to add the duration of the session to the start date to get the end date
          } else if(endDate && isGuides){
            endDate.setHours(startDate.getHours() + parseInt(currentListing.attributes.publicData.howLongActivity, 10))
          }
          //If the booking is camping set the start date to the selected start session hour
          if(startDate && (isCamping || this.props.isOvernight)){
            startDate.setHours(currentListing.attributes.publicData.startSessionTime)
          }

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                  timeZone,
                }
              : null;
          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingStartDate =
            values.bookingStartDate && values.bookingStartDate.date ? values.bookingStartDate.date : null;
          const bookingStartTime = values.bookingStartTime ? values.bookingStartTime : null;
          const bookingEndTime = values.bookingEndTime ? values.bookingEndTime : null;

          const extraFieldsDisabled = !bookingStartDate || !bookingStartTime || !bookingEndTime;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const listingTypes = currentListing.attributes.publicData.types;
          const types = filterConfig.filter(f => f.id === 'types')[0].config.options;
          const filterTypes = listingTypes && types.filter(t => listingTypes.includes(t.key)).length > 0 ? types.filter(t => listingTypes.includes(t.key)) : [{key: 'in-person', label: 'In Person'}];

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values, isCamping, currentListing);
                }}
              />
              {monthlyTimeSlots && timeZone ? (
                <div className={css.partySizeField}>
                  <FieldDateAndTimeInput
                    {...dateInputProps}
                    className={css.bookingDates}
                    listingId={listingId}
                    bookingStartLabel={bookingStartLabel}
                    onFetchTimeSlots={onFetchTimeSlots}
                    monthlyTimeSlots={monthlyTimeSlots}
                    values={values}
                    intl={intl}
                    form={form}
                    pristine={pristine}
                    timeZone={timeZone}
                    currentListing={currentListing}
                    isOvernight={isOvernight}
                    setIsOvernight={setIsOvernight}
                  />
                  {filterTypes && filterTypes.length > 0 ? (
                    <FieldSelect
                      className={css.select}
                      name="types"
                      id="types"
                      label={intl.formatMessage({ id: 'BookingTimeForm.typeLabel' })}
                      validate={required("Please select a type")}
                    >
                      <option hidden value="">
                        {intl.formatMessage({ id: 'BookingTimeForm.typePlaceholder' })}
                      </option>
                      {filterTypes.map(c => (
                        <option 
                          key={c.key}
                          value={c.key}
                          disabled={!hasPhoneNumber && c.key === 'phone-call'}
                        >
                          {c.label}
                        </option>
                      ))}
                    </FieldSelect>
                  ) : null}
                  {hasGroupClasses ? (
                    <FieldSelect
                      id="partySize"
                      name="partySize"
                      className={css.select}
                      label="Are you bringing others with you?"
                      disabled={extraFieldsDisabled}
                    >
                      {!extraFieldsDisabled ? (
                        Array.from({length: hasGroupClasses}, (x, p) => (
                          <option key={p} value={p}>
                            {p} additional person{p === 1 ? null : 's'}
                          </option>
                        ))
                      ) : (
                        <option>0-{hasGroupClasses - 1}</option>
                      )}
                    </FieldSelect>
                  ) : null}
                  {!hasPhoneNumber ? (
                    <p className={css.phoneHelper}>
                      <FormattedMessage 
                        id="BookingTimeForm.phoneRequired" 
                        values={{
                          link: <a href={process.env.REACT_APP_CANONICAL_ROOT_URL.replace(/\/$/, '') + "/account/contact-details"}>
                            <FormattedMessage id="EditListingDescriptionForm.phoneCallNoPhoneLink"/>
                            </a>
                        }}
                      />
                    </p>
                  ) : null}
                </div>
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingTimeForm.ownListing'
                      : 'BookingTimeForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit">
                  <FormattedMessage id="BookingTimeForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  filterConfig: config.custom.filters
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
