import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { isOldTotalMismatchStockError } from '../../util/errors';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldSelect, FieldTextInput } from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        types,
        maxPartySize,
        categories,
        overnight,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;
      const isCamping = categories === "Camp-Sites";
      const isInstructors = categories === "Instructors";
      const isProduct = categories === "Buy-Sell";
      const isGuides = categories === "Guides";

      const translationKey = isProduct ? 'EditListingPricingForm.pricePerItem'
       : isNightly || isCamping 
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
          ? 'EditListingPricingForm.pricePerDay'
          : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePerNightMessage = intl.formatMessage({
        id: 'EditListingPricingForm.pricePerNight',
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const phonePriceMessage = intl.formatMessage({
        id: "EditListingPricingForm.phonePriceMessage"
      })

      const phonePricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.phonePricePlaceholder',
      });

      const videoChatPriceMessage = intl.formatMessage({
        id: "EditListingPricingForm.videoChatPriceMessage"
      })

      const videoChatPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.videoChatPricePlaceholder',
      });

      const extraPersonPriceMessage = intl.formatMessage({
        id: "EditListingPricingForm.extraPersonPriceMessage"
      })

      const extraPersonPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.extraPersonPriceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const stockValidator = validators.numberAtLeast(
        intl.formatMessage({ id: 'EditListingPricingForm.stockIsRequired' }),
        0
      );

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError, setStockError } = fetchErrors || {};

      const stockErrorMessage = isOldTotalMismatchStockError(setStockError)
        ? intl.formatMessage({ id: 'EditListingPricingForm.oldStockTotalWasOutOfSync' })
        : intl.formatMessage({ id: 'EditListingPricingForm.stockUpdateFailed' });

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          {isInstructors && types.includes("in-person") ? (
            <FieldCurrencyInput
              className={css.priceInput}
              id="price"
              name="price"
              label={pricePerUnitMessage}
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
          ) : !isInstructors ? (
            <FieldCurrencyInput
              className={css.priceInput}
              id="price"
              name="price"
              label={pricePerUnitMessage}
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
          ) : null}

          {isInstructors && types.includes("phone-call") ? (
            <FieldCurrencyInput
              id="phonePrice"
              name="phonePrice"
              className={css.priceInput}
              label={phonePriceMessage}
              placeholder={phonePricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
          ) : null}

          {isInstructors && types.includes("video-chat") ? (
            <FieldCurrencyInput
              id="videoChatPrice"
              name="videoChatPrice"
              className={css.priceInput}
              label={videoChatPriceMessage}
              placeholder={videoChatPricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
          ) : null}

          {isProduct ? <FieldTextInput
            className={css.priceInput}
            id="stock"
            name="stock"
            label={intl.formatMessage({ id: 'EditListingPricingForm.stockLabel' })}
            placeholder={intl.formatMessage({ id: 'EditListingPricingForm.stockPlaceholder' })}
            type="number"
            min={0}
            validate={stockValidator}
          />
            : null}
          {isProduct && setStockError ? <p className={css.error}>{stockErrorMessage}</p> : null}

          {overnight ? <FieldCurrencyInput
            id="priceOvernight"
            name="priceOvernight"
            className={css.priceInput}
            autoFocus
            label={pricePerNightMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          /> : null}

          {isCamping || overnight ?
            <div className={css.listingHours}>
              <FieldSelect
              className={css.priceInput}
              id="startSessionTime"
              name="startSessionTime"
              label={isCamping ? "What time can guests arrive?" : "Pick up time?"}
            >
              <option key={"select"} value={""} hidden>
                {isCamping ? "Select arrival hour" : "Select pick up time"}
              </option>
              <option key={0} value={0}>
                {"00:00"}
              </option>
              <option key={1} value={1}>
                {"01:00"}
              </option>
              <option key={2} value={2}>
                {"02:00"}
              </option>
              <option key={3} value={3}>
                {"03:00"}
              </option>
              <option key={4} value={4}>
                {"04:00"}
              </option>
              <option key={5} value={5}>
                {"05:00"}
              </option>
              <option key={6} value={6}>
                {"06:00"}
              </option>
              <option key={7} value={7}>
                {"07:00"}
              </option>
              <option key={8} value={8}>
                {"08:00"}
              </option>
              <option key={9} value={9}>
                {"09:00"}
              </option>
              <option key={10} value={10}>
                {"10:00"}
              </option>
              <option key={11} value={11}>
                {"11:00"}
              </option>
              <option key={12} value={12}>
                {"12:00"}
              </option>
              <option key={13} value={13}>
                {"13:00"}
              </option>
              <option key={14} value={14}>
                {"14:00"}
              </option>
              <option key={15} value={15}>
                {"15:00"}
              </option>
              <option key={16} value={16}>
                {"16:00"}
              </option>
              <option key={17} value={17}>
                {"17:00"}
              </option>
              <option key={18} value={18}>
                {"18:00"}
              </option>
              <option key={19} value={19}>
                {"19:00"}
              </option>
              <option key={20} value={20}>
                {"20:00"}
              </option>
              <option key={21} value={21}>
                {"21:00"}
              </option>
              <option key={22} value={22}>
                {"22:00"}
              </option>
              <option key={23} value={23}>
                {"23:00"}
              </option>
              <option key={24} value={24}>
                {"24:00"}
              </option>
            </FieldSelect>
            <FieldSelect
              className={css.priceInput}
              id="endSessionTime"
              name="endSessionTime"
              label={isCamping ? "What time do guests leave?" : "Drop off time?"}
            >
              <option key={"select"} value={""} hidden>
                {isCamping ? "Select departure hour" : "Select Drop off time"}
              </option>
              <option key={0} value={0}>
                {"00:00"}
              </option>
              <option key={1} value={1}>
                {"01:00"}
              </option>
              <option key={2} value={2}>
                {"02:00"}
              </option>
              <option key={3} value={3}>
                {"03:00"}
              </option>
              <option key={4} value={4}>
                {"04:00"}
              </option>
              <option key={5} value={5}>
                {"05:00"}
              </option>
              <option key={6} value={6}>
                {"06:00"}
              </option>
              <option key={7} value={7}>
                {"07:00"}
              </option>
              <option key={8} value={8}>
                {"08:00"}
              </option>
              <option key={9} value={9}>
                {"09:00"}
              </option>
              <option key={10} value={10}>
                {"10:00"}
              </option>
              <option key={11} value={11}>
                {"11:00"}
              </option>
              <option key={12} value={12}>
                {"12:00"}
              </option>
              <option key={13} value={13}>
                {"13:00"}
              </option>
              <option key={14} value={14}>
                {"14:00"}
              </option>
              <option key={15} value={15}>
                {"15:00"}
              </option>
              <option key={16} value={16}>
                {"16:00"}
              </option>
              <option key={17} value={17}>
                {"17:00"}
              </option>
              <option key={18} value={18}>
                {"18:00"}
              </option>
              <option key={19} value={19}>
                {"19:00"}
              </option>
              <option key={20} value={20}>
                {"20:00"}
              </option>
              <option key={21} value={21}>
                {"21:00"}
              </option>
              <option key={22} value={22}>
                {"22:00"}
              </option>
              <option key={23} value={23}>
                {"23:00"}
              </option>
              <option key={24} value={24}>
                {"24:00"}
              </option>
            </FieldSelect>
            </div>
            : null}

          {(isInstructors || isGuides) && maxPartySize > 1 ? (
            <>
              <FieldCurrencyInput
                id="extraPersonPrice"
                name="extraPersonPrice"
                className={css.priceInput}
                label={extraPersonPriceMessage}
                placeholder={extraPersonPricePlaceholderMessage}
                currencyConfig={config.currencyConfig}
                validate={priceValidators}
              />
            </>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {"Next Step"}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
