import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { IconEye, IconLike, NamedLink, ResponsiveImage } from '../../components';
import MessageIcon  from '@material-ui/icons/Message';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { IconButton, CircularProgress } from '@material-ui/core';

import css from './SearchListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const SearchListingCardComponent = props => {
  const {
    className,
    currentUser,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    onToggleLike,
    likeInProgress,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, metadata } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;


  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const activities = publicData.activities ? publicData.activities : [];

  const activitiesLabel = activities.map((act, i) => {
    return act.charAt(0).toUpperCase() + act.slice(1) + (i === activities.length - 1 ? '' : ', ');
  });

  const views = publicData.views > 10000 ? parseInt(publicData.views/1000) + "K" : publicData.views ? publicData.views : 0;
  const likedBy = !!metadata?.likedBy ? metadata.likedBy : [];
  const comments = Array.isArray(metadata?.comments) ? metadata.comments.length : 0;

  const likedByCurrent = (!!currentUser && !!metadata && Array.isArray(metadata.likedBy) && metadata.likedBy.includes(currentUser.id.uuid));
  const currentLikeInProgress = likeInProgress === id;

  return (
    <div className={css.wrapperForButton}>
      <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
        <div
          className={css.listingWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={classNames(css.threeToTwoWrapper, css.imageSection)}>
            <div className={css.aspectWrapper}>
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
                sizes={renderSizes}
              />
            </div>
            
          </div>
          <div className={css.infoSection}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            <p className={css.activities}>{activitiesLabel}</p>
            <div className={css.listingMetadata}>
              <span>
                <IconEye /> {views}
              </span>
              <span>
                <IconLike /> {likedBy.length}
              </span>
              <span>
                <MessageIcon /> {comments}
              </span>
            </div>
            <div className={css.reviewAndPrice}>
              <div className={css.listingPrice}>
                <div className={css.priceValue} title={priceTitle}>
                  {formattedPrice}
                </div>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            </div>
          </div>
          <div className={css.mobileInfoSection}>
            <div className={css.mobilePrice}>
              {formattedPrice}
              <br />
              <span className={css.labelPrice}><FormattedMessage id={unitTranslationKey} /></span>
            </div>
            <div className={css.mobileInfo}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
          </div>
        </div>
      </NamedLink>
      <IconButton onClick={e => {e.stopPropagation(); onToggleLike(id)}} className={css.likeButton} aria-label="like">
        { currentLikeInProgress ? <CircularProgress color="inherit" size="24px"/> : likedByCurrent ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
      </IconButton>
    </div>
  );
};

SearchListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

SearchListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(SearchListingCardComponent);
