import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';

import css from './AboutPage.module.css';
import image from './about-us-1056.jpg';

const AboutPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Yogatime',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Find your next adventure</h1>
          <img className={css.coverImage} src={image} alt="My first ice cream." />

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <p>Funt is a platform to find coaches, guides or instructors for all kind of activities and adventures.</p>
            </div>

            <div className={css.contentMain}>
              <h2>
                Adventured’s Mission
              </h2>

              <p>
                Adventured’s mission is to connect expert teachers with passionate students.  We do this so people globally have equal access to learn their interests.  We offer students direct access to experts, and their knowledge, in-person, via video chat, or phone call for the best learning experience.  We add tremendous value to the world by offering a marketplace for teachers to teach what they love, and for students to efficiently learn what they are deeply passionate about.
              </p>
              
              <h2>
                Adventured’s Vision
              </h2>

              <p>
                Funt hopes to raise the standard of what it means to have a fun, and effective learning experience.  To provide the world with a new way of learning, by re-inventing our education system.  We believe a more fun, and effective way to learn, will make the world a better place.  Not for our own notoriety or financial compensation, but simply to show ourselves, and the world, what we believe.  By doing so, we will have accomplished our mission.
              </p>

              <h2 className={css.subtitle} id="contact">Contact</h2>
              <p>
                If you have any questions, suggestions, or problems, you can contact us on <a href="mailto:support@adventured.com">support@adventured.com</a> and we'll get right back to you.
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
