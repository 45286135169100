import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStockRaw = currentListing.currentStock?.attributes?.quantity;
  const currentStock = typeof currentStockRaw != null ? currentStockRaw : 1;

  const { price } = currentListing.attributes;
  const { endSessionTime, startSessionTime, types, maxPartySize, phonePrice, videoChatPrice, extraPersonPrice, categories, overnight } = currentListing.attributes.publicData;

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;

  const handleSubmit = (values) => {

    const updatedPhonePrice = values.phonePrice ? {
      amount: values.phonePrice.amount,
      currency: values.phonePrice.currency,
    } : null

    const updatedVideoChatPrice = values.videoChatPrice ? {
      amount: values.videoChatPrice.amount,
      currency: values.videoChatPrice.currency,
    } : null

    const pricePerNight = values.priceOvernight ? {
      amount: values.priceOvernight.amount,
      currency: values.priceOvernight.currency,
    } : null

    const updatedExtraPersonPrice = values.extraPersonPrice ? {
      amount: values.extraPersonPrice.amount,
      currency: values.extraPersonPrice.currency,
    } : null

    const { startSessionTime, endSessionTime, stock } = values;

    // Update stock only if the value has changed.
    // NOTE: this is going to be used on a separate call to API
    // in EditListingPage.duck.js: sdk.stock.compareAndSet();
    const hasStockQuantityChanged = stock && currentStockRaw !== stock;
    // currentStockRaw is null or undefined, return null - otherwise use the value
    const oldTotal = currentStockRaw != null ? currentStockRaw : null;

    const stockUpdateMaybe = hasStockQuantityChanged
      ? {
        stockUpdate: {
          oldTotal,
          newTotal: stock,
        },
      }
      : {};
    
    const updatedValues = {
      price: values.price ? values.price : new Money(100, config.currency),
      publicData: {
        phonePrice: updatedPhonePrice,
        videoChatPrice: updatedVideoChatPrice,
        extraPersonPrice: updatedExtraPersonPrice,
        startSessionTime,
        endSessionTime,
        pricePerNight,
        ...stockUpdateMaybe,
      },
    };

    onSubmit(updatedValues);
  }

  let initialValues = {
    price,
    phonePrice: phonePrice ? new Money(phonePrice.amount, phonePrice.currency) : null,
    videoChatPrice: videoChatPrice ? new Money(videoChatPrice.amount, videoChatPrice.currency) : null,
    extraPersonPrice: extraPersonPrice ? new Money(extraPersonPrice.amount, extraPersonPrice.currency) : null,
    startSessionTime,
    endSessionTime,
    stock: currentStock
  };

  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      types={types}
      maxPartySize={maxPartySize}
      categories={categories}
      overnight={overnight}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
