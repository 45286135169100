import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, NamedLink } from '../../components';
import { Avatar, ReviewRating } from '../../components';
import "@fontsource/roboto";

import css from './ListingPage.module.css';

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

const SectionHeading = props => {
  const {
    listingCertificate,
    certificateOptions,
    showContactUser,
    onContactUser,
    currentListing,
    reviewRate,
    numberOfReviews
  } = props;

  const author = currentListing.author;

  const certificate = getCertificateInfo(certificateOptions, listingCertificate);
  const showCertificate = certificate && !certificate.hideFromListingInfo;
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h2 className={css.descriptionTitle}>
          {/* <FormattedMessage id="ListingPage.descriptionTitle" /> */}
          Guide
        </h2>
        <NamedLink name="ProfilePage" params={{ id: author.id.uuid }} className={css.avatarLink}>
          <div className={css.guide}>
            <Avatar className={css.avatar} user={author} disableProfileLink />
            <div className={css.instructorReviews}>
              <h1 className={css.title}>{author.attributes.profile.displayName}</h1>

              <p className={css.metadata}>
                <ReviewRating
                  rating={reviewRate}
                  className={css.mobileReviewRating}
                  reviewStarClassName={css.reviewRatingStar}
                />
                {" " + numberOfReviews} reviews</p>
            </div>
            <div className={css.author}>
              {showCertificate ? <span>{certificate.label}</span> : null}
              {showContactUser ? (
                <span className={css.contactWrapper}>
                  {showCertificate ? <span className={css.separator}>•</span> : null}
                  <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                    <FormattedMessage id="ListingPage.contactUser" />
                  </InlineTextButton>
                </span>
              ) : null}
            </div>
          </div>
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionHeading;
