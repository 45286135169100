import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { TopbarSearchForm } from '../../forms';
import config from '../../config';

import css from './SectionHero.module.css';
import TopbarLocationForm from '../../forms/TopbarSearchForm/TopbarLocationForm';


const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const handleSubmit = (values) =>  {
    const { currentSearchParams } = props;
    const { search, selectedPlace } = values.location || {};
    const keywords = values.keywords || null;
    const {pub_types} = values || null;
    const { history } = props || {};
    const { origin, bounds } = selectedPlace || {};
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const { bounds: currentBounds } = currentSearchParams || {}
    const boundsMaybe = { bounds: currentBounds || bounds };
    const searchParams = pub_types !== 'online' ? {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      ...boundsMaybe,
      keywords,
      pub_types: "in-person"
    } : {
      keywords,
      pub_types: ["video-chat", "phone-call"]
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title2" />
        </h2>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        {/* <TopbarSearchForm className={css.heroSearchbar} desktopInputRoot={css.heroInputRoot} heroIconRoot={css.heroIconRoot} onSubmit={handleSubmit}/> */}
        <TopbarLocationForm
          className={css.heroSearchbar}
          desktopFieldsWrapper={css.fieldsWrapper}
          initialValues={{ pub_types: 'in-person' }}
          onSubmit={handleSubmit}
          isMain={true}
        />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
