import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const { options, publicData, isCamping } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.activities && isCamping && publicData.campSitesAmenities ? publicData.campSitesAmenities : publicData && publicData.activities ? publicData.activities : [];
  const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.activities"
        className={css.activityList}
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        twoColumns={selectedConfigOptions.length > 5}
        isCamping={isCamping}
      />
      {/* <hr className={css.sectionSeparator} /> */}
    </div>
  );
};

export default SectionFeaturesMaybe;
