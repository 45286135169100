import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Button, FieldSelect, Form, IconCheckmark, IconEdit, IconSearch, IconSpinner, LocationAutocompleteInput } from '../../components';
import Geocoder, { GeocoderAttribution, CURRENT_LOCATION_ID } from './GeocoderMapbox';

import css from './TopbarSearchLocationForm.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import LocationSearchForm from '../LocationSearchForm/LocationSearchForm';

const currentLocationPrediction = { id: CURRENT_LOCATION_ID, predictionPlace: {} };
const identity = v => v;
class TopbarSearchLocationFormComponent extends Component {
  constructor(props) {
    super(props);

    this.getGeocoder = this.getGeocoder.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInput = React.createRef();
  }

  getGeocoder() {
    // Create the Geocoder as late as possible only when it is needed.
    if (!this._geocoder) {
      this._geocoder = new Geocoder();
    }
    return this._geocoder;
  }

  async onSubmit(values) {
      console.log("On Submit: ", values);
    // Set a default place in case Current Location fails
    // let place = {
    //   search: '',
    //   predictions: [],
    //   selectedPlace: {},
    // }

    // try {
    //   // Set initial search on geoCoders's current location
    //   const location = await this.getGeocoder().getPlaceDetails(currentLocationPrediction);

    //   place = {
    //     search: location.address,
    //     selectedPlace: location,
    //   }

    // } catch(err) {
    //   // eslint-disable-next-line no-console
    //   console.error(err);
    // }

    // Submit form with keywords, and possibly location 
    this.props.onSubmit({ keywords: values.keywords, activity: values.activities });
    // blur search input to hide software keyboard
    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, desktopSelectRoot, heroIconRoot, intl, isMobile, handleSubmit, handleLocationSubmit, filterConfig } = formRenderProps;

          const classes = classNames(rootClassName, className, isMobile ? css.mobileInput : null);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;
          const desktopSelectRootClass = desktopSelectRoot || css.desktopSelectRoot;
          const categoryOptions = findOptionsForSelectFilter('activities', filterConfig);
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.fieldsWrapper}>
              <div className={isMobile ? css.mobileSelectRoot : desktopSelectRootClass}>
              <IconSearch rootClassName={css.iconRoot} />
              <FieldSelect 
                  className={css.desktopSelectElement}
                  selectClassName={css.desktopSelectElement}
                  id="activities"
                  name="activities"
                >
                  <option value="" disabled>{intl.formatMessage({ id: 'TopbarSearchLocationForm.categoryPlaceholder'})}</option>
                  {categoryOptions.map(item => (
                    <option value={item.key} key={item.key}>{item.label}</option>
                  ))}
                </FieldSelect>
              </div>
              <Field
                name="keywords"
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;
                  const searchOnChange = value => {
                    onChange(value);
                    // handleChange(value);
                  };
  
                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (
                    <div className={isMobile ? css.mobileInputRoot : desktopInputRootClass}>
                        <LocationAutocompleteInput
                            placeholder={intl.formatMessage({ id: 'TopbarSearchLocationForm.placeholder' })}
                            iconClassName={css.searchInputIcon}
                            inputClassName={css.searchInput}
                            predictionsClassName={css.searchPredictions}
                            input={searchInput}
                            meta={meta}
                        />
                    </div>
                    
                  );
                }}
              />
              <Button type="submit" className={css.submitButton}>
                <span className={css.buttonText}>{intl.formatMessage({id: 'TopbarSearchLocationForm.search'})}</span>
              </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchLocationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  filterConfig: config.custom.filters
};

TopbarSearchLocationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchLocationForm = injectIntl(TopbarSearchLocationFormComponent);

export default TopbarSearchLocationForm;
