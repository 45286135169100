import React, { useState } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionFeatured,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  SectionNewsteller,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/yogatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import { findOptionsForSelectFilter } from '../../util/search'
import { queryFeaturedListings, loadData, toggleLike, signupNewsteller } from './LandingPage.duck';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    filterConfig,
    featuredListings,
    featuredListingsProgress,
    pagination,
    loadListingsHandler,
    onToggleLike,
    likeInProgress,
    onSignupNewsteller,
    newstellerSignup,
    currentUser
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const options = findOptionsForSelectFilter('activities', filterConfig);

      //Horizontal scroll
      const [currentActivity, setCurrentActivity] = useState("Popular")

      const handleToggleLike = (listingId) => {
        if (!!currentUserListing) {
          onToggleLike(currentUserListing.id.uuid, listingId);
        } else {
          const state = { from: `${location.pathname}${location.search}${location.hash}` };
          history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
        }
      }

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              options={options}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionFeatured
                  featuredListings={featuredListings}
                  inProgress={featuredListingsProgress}
                  loadListingsHandler={loadListingsHandler}
                  pagination={pagination}
                  history={props.history}
                  currentActivity={currentActivity}
                  setCurrentActivity={setCurrentActivity}
                  onToggleLike={handleToggleLike}
                  likeInProgress={likeInProgress}
                  currentUser={currentUserListing}
                />
              </div>
            </li>
            <li className={css.sectionHow}>
              <div className={css.sectionContent}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                  className={css.howitworks}
                />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
  filterConfig: config.custom.filters,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  filterConfig: propTypes.filterConfig,
};

const mapDispatchToProps = dispatch => ({
  loadListingsHandler: (params, currentActivity) => dispatch(queryFeaturedListings(params)),
  onToggleLike: (user, listingId) => dispatch(toggleLike(user, listingId)),
  onSignupNewsteller: (email) => dispatch(signupNewsteller(email)),
});

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched, currentUser } = state.user;

  const { featuredListingsIds, featuredPagination, queryListingsInProgress, likeInProgress, newstellerSignup } = state.LandingPage;

  const featuredListings = getListingsById(state, featuredListingsIds);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    featuredListings,
    featuredListingsProgress: queryListingsInProgress,
    pagination: featuredPagination,
    likeInProgress,
    newstellerSignup,
  };
};


// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = () => {
  return loadData();
};

export default LandingPage;
