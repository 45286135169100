/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

/*
  TO ADD MORE CATEGORIES YOU SHOULD ADD THE CATEOGRY OBJECT TO THE FILTES ARRAY THEN ADD THE CATEGORIES TO THE ACTIVITIES OBJECT
{
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['pub_categories'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones

      // Options for the minimum duration of the booking
      options: [
        { key: 'Buy-Sell', label: 'Buy/Sell' },
        { key: 'Camp-Sites', label: 'Camp Sites' },
        { key: 'Guides', label: 'Guides' },
        { key: 'Instructors', label: 'Instructors' },
        { key: 'Rentals', label: 'Rentals' },
      ],
    },
  },
*/
export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 500,
      step: 1,
    },
  },
  {
    id: 'types',
    label: 'Types',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_types'],
    config: {
      searchMode: 'has_any',
      options: [
        { key: 'in-person', label: 'In Person' },
        { key: 'video-chat', label: 'Video Chat' },
        { key: 'phone-call', label: 'Phone Call' },
      ],
    },
  },
  {
    id: 'activities',
    label: 'Activities',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_activities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'aviation', label: 'Aviation', showLanding: false, categories: ["Instructors"]},
        { key: 'base-jumping', label: 'BASE jumping', showLanding: false, categories: ["Instructors"]},
        { key: 'boxing', label: 'Boxing', showLanding: false, categories: ["Instructors"]},
        { key: 'bmx', label: 'BMX', showLanding: false, categories: ["Instructors"]},
        { key: 'business', label: 'Business', showLanding: false, categories: ["Instructors"]},
        { key: 'cooking', label: 'Cooking', showLanding: false, categories: ["Instructors"]},
        { key: 'dating-advice', label: 'Dating Advice', showLanding: false, categories: ["Instructors"]},
        { key: 'driving', label: 'Driving', showLanding: false, categories: ["Instructors"]},
        { key: 'family-advice', label: 'Family Advice', showLanding: false, categories: ["Instructors"]},
        { key: 'fishing', label: 'Fishing', showLanding: false, categories: ["Instructors"]},
        { key: 'foil-boarding', label: 'Foil Boarding', showLanding: false, categories: ["Instructors"]},
        { key: 'guitar', label: 'Guitar', showLanding: false, categories: ["Instructors"]},
        { key: 'ice-climbing', label: 'Ice-climbing', showLanding: false, categories: ["Instructors"]},
        { key: 'jiu-jitsu', label: 'Jiu-Jitsu', showLanding: false, categories: ["Instructors"]},
        { key: 'kitesurfing', label: 'Kitesurfing', showLanding: false, categories: ["Instructors"]},
        { key: 'life-coaching', label: 'Life-Coaching', showLanding: false, categories: ["Instructors"]},
        { key: 'mma', label: 'MMA', showLanding: false, categories: ["Instructors"]},
        { key: 'meditation', label: 'Meditation', showLanding: false, categories: ["Instructors"]},
        { key: 'mediumship', label: 'Mediumship', showLanding: false, categories: ["Instructors"]},
        { key: 'mental-health', label: 'Mental Health', showLanding: false, categories: ["Instructors"]},
        { key: 'motocross', label: 'Motocross', showLanding: false, categories: ["Instructors"]},
        { key: 'mountainbiking', label: 'Mountainbiking', showLanding: false, categories: ["Instructors"]},
        { key: 'music', label: 'Music', showLanding: false, categories: ["Instructors"]},
        { key: 'paragliding', label: 'Paragliding', showLanding: false, categories: ["Instructors"]},
        { key: 'paramotoring', label: 'Paramotoring', showLanding: false, categories: ["Instructors"]},
        { key: 'photography', label: 'Photography', showLanding: false, categories: ["Instructors"]},
        { key: 'photo-editing', label: 'Photo Editing', showLanding: false, categories: ["Instructors"]},
        { key: 'psychology', label: 'Psychology', showLanding: false, categories: ["Instructors"]},
        { key: 'relationship-advice', label: 'Relationship advice', showLanding: false, categories: ["Instructors"]},
        { key: 'rockclimbing', label: 'Rockclimbing', showLanding: false, categories: ["Instructors"]},
        { key: 'scuba-diving', label: 'Scuba Diving', showLanding: false, categories: ["Instructors"]},
        { key: 'singing', label: 'Singing', showLanding: false, categories: ["Instructors"]},
        { key: 'social-media', label: 'Social-Media', showLanding: false, categories: ["Instructors"]},
        { key: 'self-defense', label: 'Self-Defense', showLanding: false, categories: ["Instructors"]},
        { key: 'shooting', label: 'Shooting', showLanding: false, categories: ["Instructors"]},
        { key: 'skateboarding', label: 'Skateboarding', showLanding: false, categories: ["Instructors"]},
        { key: 'slacklining', label: 'Slacklining', showLanding: false, categories: ["Instructors"]},
        { key: 'skiing', label: 'Skiing', showLanding: false, categories: ["Instructors"]},
        { key: 'skydiving', label: 'Skydiving', showLanding: false, categories: ["Instructors"]},
        { key: 'snowboarding', label: 'Snowboarding', showLanding: false, categories: ["Instructors"]},
        { key: 'snowmobiling', label: 'Snowmobiling', showLanding: false, categories: ["Instructors"]},
        { key: 'stand-up-paddle', label: 'Stand Up Paddle', showLanding: false, categories: ["Instructors"]},
        { key: 'surfing', label: 'Surfing', showLanding: false, categories: ["Instructors"]},
        { key: 'videography', label: 'Videography', showLanding: false, categories: ["Instructors"]},
        { key: 'video-editing', label: 'Video Editing', showLanding: false, categories: ["Instructors"]},
        { key: 'wake-board-surf', label: 'Wake board/surf', showLanding: false, categories: ["Instructors"]},
        { key: 'windsurfing', label: 'Windsurfing', showLanding: false, categories: ["Instructors"]},
        { key: 'yoga', label: 'Yoga', showLanding: false, categories: ["Instructors"]},
      ],
    },
  },

];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: false,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const campSitesAmenities = [
  { key: 'campfire', label: 'Campfires allowed?', labelYes: 'Campfires allowed', labelNo: 'Campfires not allowed' },
  { key: 'toilet', label: 'Toilet available?',labelYes: 'Toilet available', labelNo: 'Toilet not available' },
  { key: 'pet', label: 'Pets allowed?', labelYes: 'Pets allowed', labelNo: 'Pets not allowed' },
  { key: 'kitchen', label: 'Kitchen available?',labelYes: 'Kitchen available', labelNo: 'Kitchen not available' },
  { key: 'picnic', label: 'Picnic available?', labelYes: 'Picnic available', labelNo: 'Picnic not available' },
  { key: 'wifi', label: 'Wifi available?', labelYes: 'Wifi available', labelNo: 'Wifi not available' },
  { key: 'trashBins', label: 'Trash bins available?', labelYes: 'Trash bins available', labelNo: 'Trash bins not available' },
  { key: 'portableWater', label: 'Portable Water available?', labelYes: 'Portable Water available', labelNo: 'No portable water' },
  { key: 'showers', label: 'Showers available?', labelYes: 'Showers available', labelNo: 'No showers' },
  { key: 'laundry', label: 'Laundry available?', labelYes: 'Laundry available', labelNo: 'No laundry' },
]