/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImageFromFile, ResponsiveImage, IconSpinner } from '../../components';

import css from './AddImages.module.css';
import RemoveImageButton from './RemoveImageButton';
import { Image2XAspect } from '../ResponsiveImage/ResponsiveImage.example';

const ThumbnailWrapper = props => {
  const { className, image, savedImageAltText, onRemoveImage, updateImageOrder, setTouchDrag, updateImageOrder2 } = props;
  const handleRemoveClick = e => {
    e.stopPropagation();
    onRemoveImage(image.id);
  };

  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image.imageId ? <RemoveImageButton onClick={handleRemoveClick} /> : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;

    return (
      <ImageFromFile
        id={image.id}
        className={className}
        rootClassName={css.thumbnail}
        file={image.file}
        draggable={true}
        onDragEnter={function (event) {
          event.target.classList.add(css.dragOver);
        }}
        onDragLeave={function (event) {
          event.target.classList.remove(css.dragOver);
        }}
        onDragStart={function (e) {
          // Set the id of the image that was dragged          
          e.dataTransfer.setData('image', image.id);
        }}
        onTouchStart={function (e) {
          // Set the id of the image that was dragged          
          setTouchDrag(image.id);
        }}
        onTouchMove={updateImageOrder}
        onDrop={updateImageOrder}
      >
        {removeButton}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, className);
    return (
      <div className={classes}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              id={image.id.uuid}
              rootClassName={css.rootForImage}
              image={image}
              alt={savedImageAltText}
              variants={['landscape-crop', 'landscape-crop2x']}
              draggable={true}
              onDragEnter={function (event) {
                event.target.classList.add(css.dragOver);
              }}
              onDragLeave={function (event) {
                event.target.classList.remove(css.dragOver);
              }}
              onDragStart={function (e) {
                // Set the id of the image that was dragged
                e.dataTransfer.setData('image', image.id.uuid);
              }}
              onTouchStart={function (e) {
                // Set the id of the image that was dragged          
                setTouchDrag(image.id.uuid);
              }}
              onTouchEnd={updateImageOrder2}
              onDrop={updateImageOrder}
            />
          </div>
          <RemoveImageButton onClick={handleRemoveClick} />
        </div>
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

const AddImages = props => {
  const {
    children,
    className,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    onUpdateImageOrder
  } = props;

  const classes = classNames(css.root, className);

  const [touchDrag, setTouchDrag] = useState(false);

  // For mobile
  const updateImageOrder2 = (event) => {
    event.stopPropagation();

    // Get the id of the image that was dragged
    const imageId =  touchDrag;
    // Get the id of the image that was dropped
    const target = document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY).id;

    // Get the index of the image dragged
    const targetIndex = images.findIndex(image => image.id.uuid === target || image.id === target);
    // Get the index of the image dropped
    const imageIndex = images.findIndex(image => image.id.uuid === imageId || image.id === imageId);

    // Get the id's of the images in the correct order
    const imageOrder = images.map(i => i.id.uuid || i.id);

    // Get the new order of the images
    imageOrder.splice(targetIndex, 0, imageId);

    // If the target has higher index than the image delete the original index
    if(targetIndex > imageIndex) {
      imageOrder.splice(imageIndex, 1);
    // Else delete the one higher than the original index
    } else {
      imageOrder.splice(imageIndex + 1, 1);
    }

    //Send the new order to the parent component
    onUpdateImageOrder(imageOrder);
  }

  const updateImageOrder = (event) => {
    event.preventDefault();
    event.stopPropagation();

    event.target.classList.remove(css.dragOver);

    // Get the id of the image that was dragged
    const imageId = event.dataTransfer.getData('image') || touchDrag;
    // Get the id of the image that was dropped
    const target = event.target.id || event.currentTarget;

    // Get the index of the image dragged
    const targetIndex = images.findIndex(image => image.id.uuid === target || image.id === target);
    // Get the index of the image dropped
    const imageIndex = images.findIndex(image => image.id.uuid === imageId || image.id === imageId);

    // Get the id's of the images in the correct order
    const imageOrder = images.map(i => i.id.uuid || i.id);

    // Get the new order of the images
    imageOrder.splice(targetIndex, 0, imageId);

    // If the target has higher index than the image delete the original index
    if(targetIndex > imageIndex) {
      imageOrder.splice(imageIndex, 1);
    // Else delete the one higher than the original index
    } else {
      imageOrder.splice(imageIndex + 1, 1);
    }

    //Send the new order to the parent component
    onUpdateImageOrder(imageOrder);
  }




  return (
    <div className={classes}>
      {images.map((image, index) => {
        return (
          <ThumbnailWrapper
            image={image}
            index={index}
            key={image.id.uuid || image.id}
            className={thumbnailClassName}
            savedImageAltText={savedImageAltText}
            onRemoveImage={onRemoveImage}
            updateImageOrder={updateImageOrder}
            setTouchDrag={setTouchDrag}
            updateImageOrder2={updateImageOrder2}
          />
        );
      })}
      {children}
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default AddImages;
