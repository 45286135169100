/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_ADDITIONAL_PERSON, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemAdditionalPersonsMaybe = props => {
  const { transaction, booking, intl } = props;

  // resolve unknown non-reversal line items
  const additionalPersonItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_ADDITIONAL_PERSON && !item.reversal
  );

  if (!additionalPersonItem) {
    return null;
  }

  const quantity = additionalPersonItem.quantity;

  const { start = new Date(), end = new Date() } = booking.attributes || {};
  const hours = Math.abs(start - end) / 36e5;

  const label = `Additional person(s) x ${hours} hour(s)`

  const formattedTotal = formatMoney(intl, additionalPersonItem.lineTotal);

  return additionalPersonItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>{label}</span>
      <span className={css.itemValue}>{formattedTotal}</span>
    </div>
  ) : null;
};

LineItemAdditionalPersonsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAdditionalPersonsMaybe;
